<template>
    <div class="catalog-page-detail-content-tab" v-loading="pageLoading">
        <el-card class="custom-padding">
            <template slot="header">
                <div class="title">
                    <icon :icon="tab.icon" />
                    {{ tab.title }}
                </div>
                <div class="actions">
                    <el-tooltip content="Ekle" placement="left">
                        <el-button class="flat dark blue" icon="el-icon-plus" @click="create" />
                    </el-tooltip>
                </div>
            </template>

            <template v-for="(row, index) in response.rows">
                <div class="product-detail-row" :key="index" :class="{active : row.is_main}">
                    <div class="content" @click="edit(row.id)">
                        <div class="media" v-if="row.teacher && $route.meta.type === 'group'">
                            <pic :src="row.teacher.avatar" type="avatar" class="avatar" />
                        </div>
                        <div class="text">
                            <div class="title" v-if="row.teacher && $route.meta.type === 'group'">
                                {{ row.teacher.name }}
                            </div>
                            <div class="title" v-if="$route.meta.type !== 'group'">
                                {{ row.reference_name }}
                            </div>
                            <div class="metas">
                                <div class="meta">
                                    <icon icon="barcode" />
                                    {{ row.reference }}
                                </div>
                                <div class="meta" v-if="row.variant_type && $route.meta.type === 'group'">
                                    <icon icon="vhs" />
                                    {{ findProductType(row.variant_type) }}
                                </div>

                                <div class="meta">
                                    <icon icon="timer-play-outline" />
                                    {{ row.start_at }}
                                </div>

                                <div class="meta">
                                    <icon icon="timer-check-outline" />
                                    {{ row.end_at }}
                                </div>

                                <div class="meta prices">
                                    <div class="prices" v-if="row.discount">
                                        <div class="old text-red">
                                            <del><b>{{ $n(row.price, 'currency') }}</b></del>
                                        </div>
                                        <div class="text-green">
                                            <b>{{ $n(row.discount.price, 'currency') }}</b>
                                        </div>
                                    </div>
                                    <div v-if="!row.discount">
                                        <b>{{ $n(row.price, 'currency') }}</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="actions">
                        <el-tooltip content="Sil" placement="left">
                            <el-button type="text" class="flat dark red large" icon="el-icon-delete" @click="destroy(row.id)"/>
                        </el-tooltip>
                    </div>
                </div>
            </template>
        </el-card>

        <div class="text-center margin-top-20" v-if="response.pagination.total > response.pagination.per_page">
            <el-pagination
                background
                layout="prev, pager, next"
                :total="response.pagination.total"
                :page-size="response.pagination.per_page"
                :current-page.sync="response.pagination.current_page"
                @current-change="handleChangePage"
            />
        </div>

        <el-dialog title="Urun Bilgileri" :visible.sync="dialogVisible" v-loading="pageLoading" class="custom-padding" width="80%">
            <el-form :label-position="'top'" ref="form" name="form" :model="form" :key="form.id">
                <el-card shadow="never" class="no-shadow custom-padding">
                    <div class="el-card__tabs custom-padding">
                        <el-tabs class="el-card--tabs fluid" :tab-position="'top'">
                            <el-tab-pane label="Genel">
                                <div class="card-custom-padding-box-items">
                                    <el-form-item label="Marka">
                                        <ti-select :options="brands" :container="form" model="brand_id" placeholder="Marka" />
                                    </el-form-item>

                                    <el-form-item label="Urun Kodu">
                                        <el-input v-model="form.reference" placeholder="Urun Kodu" prefix-icon="mdi mdi-barcode" />
                                    </el-form-item>

                                    <el-form-item label="Urun Adi">
                                        <el-input v-model="form.reference_name" placeholder="Urun Adi" prefix-icon="mdi mdi-subtitles-outline" />
                                    </el-form-item>

                                    <el-row :gutter="24">
                                        <el-col :span="12">
                                            <el-form-item label="Satis Baslangic Tarihi">
                                                <el-date-picker v-model="form.start_at" type="datetime" placeholder="Baslangic Tarihi" value-format="yyyy-MM-dd HH:mm:ss" />
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="12">
                                            <el-form-item label="Satis Bitis Tarihi">
                                                <el-date-picker v-model="form.end_at" type="datetime" placeholder="Bitis Tarihi" value-format="yyyy-MM-dd HH:mm:ss" />
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                </div>
                                <div class="card-sub-title">Ozellikler</div>
                                <div class="card-custom-padding-box-items">
                                    <el-form-item label="Siralama">
                                        <el-input v-model="form.sort" placeholder="Siralama" type="number" prefix-icon="mdi mdi-counter" />
                                        <el-alert type="warning" :closable="false" v-if="form.type === 'subscription'">
                                            Siralama bilgisi girerken lutfen aya gore ardisik olacak sekilde siralamasini girmeyi unutmayiniz. Ornek: urun 3 Aylik ise siralamasini 3 olarak giriniz.
                                        </el-alert>
                                    </el-form-item>
                                    <el-form-item class="m-0">
                                        <el-switch v-model="form.is_active" active-text="Aktif" />
                                    </el-form-item>
                                    <el-form-item class="m-0">
                                        <el-switch v-model="form.is_main" active-text="Ana Urun" />
                                    </el-form-item>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="Fiyat">
                                <div class="card-custom-padding-box-items">
                                    <el-form-item label="Vergi Orani">
                                        <definitions :container="form" model="tax_class_definition_id" alias="tax-classes" />
                                    </el-form-item>

                                    <el-row :gutter="24">
                                        <el-col :span="12">
                                            <el-form-item label="Urun Fiyati" v-if="form.type !== 'package'">
                                                <el-input v-model="form.price" placeholder="Urun Fiyati" />
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="12">
                                            <el-form-item label="Ders Birim Fiyati">
                                                <el-input v-model="form.lesson_price" placeholder="Ders Birim Fiyati" />
                                            </el-form-item>
                                        </el-col>
                                    </el-row>

                                    <el-row :gutter="24">
                                        <el-col :span="12">
                                            <el-form-item label="Stokta Yoksa Durumu">
                                                <statuses :container="form" model="stock_status_id" alias="stocks" />
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="12">
                                            <el-form-item label="Kargolu Urun">
                                                <lv-select :container="form" model="is_shipping" :options="approveOptionsBoolean" />
                                            </el-form-item>
                                        </el-col>
                                    </el-row>

                                    <el-row :gutter="24">
                                        <el-col :span="12">
                                            <el-form-item label="Satis Adeti">
                                                <el-input v-model="form.quantity" placeholder="Adet" type="number" />
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="12">
                                            <el-form-item label="Guncel Satis Durumu">
                                                <el-input v-model="form.current_quantity" placeholder="Guncel Durum" type="number" disabled />
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                </div>

                                <div class="card-sub-title">
                                    <div>Indirim</div>
                                    <div>
                                        <el-button icon="el-icon-plus" class="flat dark blue" @click="createDiscount" />
                                    </div>
                                </div>

                                <empty v-if="!elementSize(form.discounts)" />

                                <el-tabs v-model="discountTab" class="el-card--tabs fluid" :tab-position="'top'" closable @tab-remove="destroyDiscount">
                                    <el-tab-pane :label="'Indirim ' + (key + 1)" v-for="(discount, key) in form.discounts" :key="'key-' + key" :name="'' + key">
                                        <div class="card-custom-padding-box-items">
                                            <el-row :gutter="24">
                                                <el-col :span="12">
                                                    <el-form-item label="Indirim Baslangic Tarihi">
                                                        <el-date-picker v-model="discount.start_at" type="date" placeholder="Indirim Baslangic Tarihi" value-format="yyyy-MM-dd" />
                                                    </el-form-item>
                                                </el-col>
                                                <el-col :span="12">
                                                    <el-form-item label="Indirim Bitis Tarihi">
                                                        <el-date-picker v-model="discount.end_at" type="date" placeholder="Indirim Bitis Tarihi" value-format="yyyy-MM-dd" />
                                                    </el-form-item>
                                                </el-col>
                                            </el-row>

                                            <el-form-item label="Adet">
                                                <el-input v-model="discount.quantity" placeholder="Adet" type="number" />
                                            </el-form-item>

                                            <el-row :gutter="24" v-if="form.type !== 'package'">
                                                <el-col :span="12">
                                                    <el-form-item label="Fiyat">
                                                        <el-input v-model="discount.price" placeholder="Fiyat" />
                                                    </el-form-item>
                                                </el-col>
                                                <el-col :span="12">
                                                    <el-form-item label="Ders Fiyati">
                                                        <el-input v-model="discount.per_price" placeholder="Ders Fiyati" />
                                                    </el-form-item>
                                                </el-col>
                                            </el-row>

                                            <el-form-item label="Oran">
                                                <div class="ib-groups">
                                                    <el-input v-model="discount.percent" placeholder="Indirim Orani"/>

                                                    <el-tooltip content="Oranı Hesapla" placement="top">
                                                        <el-button class="flat dark blue" @click="handleFindDiscountPercent(discount)"
                                                                   :loading="discountPercentLoading">
                                                            <icon icon="refresh"></icon>
                                                        </el-button>
                                                    </el-tooltip>
                                                </div>
                                            </el-form-item>

                                            <el-form-item label="Indirim Aciklamasi" v-if="form.type !== 'package'">
                                                <el-input v-model="discount.description" placeholder="Indirim Aciklamasi" type="textarea" rows="2" />
                                            </el-form-item>
                                        </div>
                                    </el-tab-pane>
                                </el-tabs>
                            </el-tab-pane>
                            <el-tab-pane label="Egitim">
                                <div class="card-custom-padding-box-items">
                                    <template v-if="form.type === 'group'">
                                        <el-form-item label="Egitmen" >
                                            <user :container="form" model="user_id" placeholder="Egitmen" :key="form.id" />
                                        </el-form-item>

                                        <el-row :gutter="24">
                                            <el-col :span="12">
                                                <el-form-item label="Egitim Baslangic Tarihi">
                                                    <el-date-picker v-model="form.meta.education_start_at" type="datetime" placeholder="Egitim Baslangic Tarihi" value-format="yyyy-MM-dd HH:mm:ss" />
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="12">
                                                <el-form-item label="Egitim Bitis Tarihi">
                                                    <el-date-picker v-model="form.meta.education_end_at" type="datetime" placeholder="Egitim Bitis Tarihi" value-format="yyyy-MM-dd HH:mm:ss" />
                                                </el-form-item>
                                            </el-col>
                                        </el-row>
                                    </template>

                                    <el-form-item label="Kullanim Suresi">
                                        <el-input v-model="form.meta.lesson_usage_time" placeholder="Kullanim Suresi" type="number" prefix-icon="el-icon-time">
                                            <template slot="append">ay</template>
                                        </el-input>
                                        <el-alert type="warning" :closable="false" v-if="form.type === 'subscription'">
                                            Satin Almadan Itibaren Musterinin Bu Urune Erise Bilecegi Sure
                                        </el-alert>
                                    </el-form-item>

                                    <el-row :gutter="24">
                                        <el-col :span="12">
                                            <el-form-item label="Toplam Ders Adeti">
                                                <el-input v-model="form.meta.lesson_count" placeholder="Toplam Ders Adeti" type="number" prefix-icon="mdi mdi-counter">
                                                    <template slot="append">adet</template>
                                                </el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="12">
                                            <el-form-item label="Toplam Ders Saati">
                                                <el-input v-model="form.meta.lesson_time_count" placeholder="Toplam Ders Saati" type="number" prefix-icon="mdi mdi-timer-sand-complete">
                                                    <template slot="append">saat</template>
                                                </el-input>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>

                                    <el-row :gutter="24">
                                        <el-col :span="12">
                                            <el-form-item label="Gunler">
                                                <definition-box alias="days" :container="form.meta" model="days" placeholder="Gunler" :multiple="true" />
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="12">
                                            <el-form-item label="Gün Mesaji (Tooltip de gosteriliecek alan)">
                                                <el-input v-model="form.meta.day_text" placeholder="Gün Mesaji" prefix-icon="el-icon-chat-line-square" />
                                            </el-form-item>
                                        </el-col>
                                    </el-row>

                                    <el-row :gutter="24"  v-if="form.type === 'group'">
                                        <el-col :span="12">
                                            <el-form-item label="Varyasyon">
                                                <lv-select :container="form" model="variant_type" :options="variantTypes" />
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="6">
                                            <el-form-item label="Hafta Turu">
                                                <lv-select :container="form" model="week_type" :options="weekTypes" />
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="6">
                                            <el-form-item label="Gun Turu">
                                                <lv-select :container="form" model="day_type" :options="dayTypes" />
                                            </el-form-item>
                                        </el-col>
                                    </el-row>

                                    <el-row :gutter="24">
                                        <el-col :span="12">
                                            <el-form-item label="Periyod">
                                                <definition-box alias="periods" :container="form" model="period_definition_id" placeholder="Periyod" />
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="12">
                                            <el-form-item label="Sezon">
                                                <definition-box alias="seasons" :container="form" model="season_definition_id" placeholder="Sezon" />
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="Tasarim">
                                <div class="card-custom-padding-box-items">
                                    <el-form-item label="Band">
                                        <el-input v-model="form.meta.band" type="textarea" placeholder="Band" :rows="4" />
                                    </el-form-item>
                                    <el-form-item label="Ozellikler">
                                        <el-input v-model="form.meta.feature_text" type="textarea" placeholder="Ozellikler" :rows="6" />
                                    </el-form-item>
                                </div>

                                <video-form :container="form.meta" />
                            </el-tab-pane>
                        </el-tabs>
                    </div>
                </el-card>
            </el-form>
            <template slot="footer" class="dialog-footer">
                <el-button class="fluid flat dark green large" @click="store" v-if="!form.id">Kaydet</el-button>
                <el-button class="fluid flat dark green large" @click="update" v-if="form.id">Guncelle</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'ProductProducts',
    props: ['tab', 'id'],
    data() {
        return {
            pageLoading: false,
            page: 1,
            response : {
                pagination: {
                    //
                },
                rows: []
            },

            dialogVisible: false,

            form: {
                type: 'group',
                discount: {
                    //
                },
                meta: {
                    //
                },
                discounts: []
            },

            users: [],
            brands: [],
            contents : [],

            weekTypes: [
                {
                    value: 'weekend',
                    label: 'Hafta Sonu',
                },
                {
                    value: 'weekdays',
                    label: 'Hafta Ici',
                }
            ],
            dayTypes: [
                {
                    value: 'night',
                    label: 'Gece',
                },
                {
                    value: 'day',
                    label: 'Gunduz',
                }
            ],
            variantTypes: [
                {
                    value: 'live_and_record',
                    label: 'Canli ve Kayittan',
                },
                {
                    value: 'live',
                    label: 'Canli',
                },
                {
                    value: 'record',
                    label: 'Kayittan',
                }
            ],

            discountTab: null,
            discountPercentLoading: false
        }
    },
    mounted() {
        this.index();
    },
    methods: {
        endPoint(suffix) {

            const path = 'catalog/products';

            if (suffix) {
                return path + '/' + suffix;
            }

            return path;
        },

        handleChangePage(page) {
            this.page = page;
            this.index();
        },

        index() {
            this.pageLoading = true;

            this.$http
                .get(this.endPoint(), {
                    params: {
                        page: this.page,
                        sorts: {
                            created_at: 'desc'
                        },
                        filters: [
                            {
                                relation: null,
                                add_with: 'true',
                                inputs: [
                                    {
                                        name: 'content_id',
                                        field: 'content_id',
                                        type: 'singleSelect',
                                        value: this.id
                                    }
                                ]
                            },
                        ],
                    }
                })
                .then(response => {
                    this.response = response.body;
                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        create() {
            this.initRelationItems();

            this.form = {
                type: this.$route.meta.type,
                variant_type: 'live_and_record',
                discount: {
                    //
                },
                meta: {
                    //
                },

                discounts: []
            };

            this.dialogVisible = true;
        },

        store() {
            this.pageLoading = true;
            this.form.content_id = this.id;

            this.$http
                .post(this.endPoint(), this.form)
                .then(response => {
                    this.pageLoading = false;
                    this.dialogVisible = false;

                    this.index();

                    return this.responseSuccess(response);
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        edit(id) {
            this.initRelationItems();

            this.form = {
                type: this.$route.meta.type,
                discount: {
                    //
                },
                meta: {
                    //
                },

                discounts: []
            };

            this.dialogVisible = true;

            this.show(id);
        },

        show(id) {
            this.pageLoading = true;

            this.$http
                .get(this.endPoint(id))
                .then(response => {
                    this.form = response.body.data;

                    this.form.content = null;
                    this.form.length_definition = null;
                    this.form.lessons = null;
                    this.form.books = null;
                    this.form.stock_status = null;
                    this.form.tax_definition = null;
                    this.form.teacher = null;
                    this.form.weight_definition = null;

                    if (!this.lodash.size(this.form.discount)) {
                        this.form.discount = {};
                    }

                    if (!this.lodash.size(this.form.discounts)) {
                        this.form.discounts = [];
                    }

                    if (!this.lodash.size(this.form.meta)) {
                        this.form.meta = {
                            //
                        };
                    }

                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        update() {
            this.pageLoading = true;
            this.form.discount = null;

            this.$http
                .put(this.endPoint(this.form.id), this.form)
                .then(response => {
                    this.pageLoading = false;
                    this.dialogVisible = false;

                    this.index();

                    return this.responseSuccess(response);
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        destroy(id) {

            if (this.elementSize(this.response.rows) === 1) {
                return  this.$notify.error({
                    title: 'Hata!',
                    message: 'Aktif icerik, en az bir urune sahip olmasi gerekiyor. Bu urunu silebilmek icin lutfen baska bir urun ekleyip daha sonra siliniz.'
                });
            }

            this
                .$confirm('Verileriniz kalici olarak siliniyor. Onayliyor musunuz?', 'Uyari', {
                    confirmButtonText: 'Onayla',
                    cancelButtonText: 'Iptal',
                    type: 'warning'
                })
                .then(() => {

                    this.$http
                        .delete(this.endPoint(id))
                        .then(response => {

                            this.index();

                            this.responseSuccess(response);

                        })
                        .catch(response => {
                            this.responseError(response);
                        });


                    return true;

                })
                .catch((response) => {
                    this.$notify({
                        type: 'success',
                        message: 'Isleminiz basarili bir sekilde iptal edildi!' + response
                    });

                    return false;
                });
        },

        initRelationItems() {

            if (!this.lodash.size(this.brands)) {
                this.getBrands();
            }

            if (!this.lodash.size(this.users)) {
                this.getUsers();
            }

            if (!this.lodash.size(this.contents)) {
                this.getContents();
            }
        },

        getBrands() {
            this.pageLoading = true;

            this.$http
                .get('catalog/brands', {params: {perPage: 1000}})
                .then(response => {
                    this.brands = response.body.rows;
                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        getUsers() {
            this.pageLoading = true;

            this.$http
                .get('crm/roles/teacher/users', {params: {perPage: 1000}})
                .then(response => {
                    this.users = response.body.rows;
                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        getContents() {
            this.pageLoading = true;

            this.$http
                .get('cms/teachers/contents', {params: {perPage: 1000, view: 'dropdown'}})
                .then(response => {
                    this.contents = response.body.data;
                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        createDiscount() {
            this.form.discounts.push({
                start_at : null,
                end_at : null,
                quantity : null,
                per_price : null,
                price : null,
                percent : null,
                description : null,
            })
        },

        destroyDiscount(key) {

            this
                .$confirm('Indirim ' + (parseInt(key) + 1) + ' kalici olarak silinecek!', 'Uyari', {
                    confirmButtonText: 'Onayla',
                    cancelButtonText: 'Iptal',
                    type: 'warning'
                })
                .then(() => {
                    this.form.discounts.splice(key, 1);
                    return true;
                })
                .catch(() => {
                    this.$notify({
                        type: 'success',
                        message: 'Isleminiz basarili bir sekilde iptal edildi!'
                    });

                    return false;
                });
        },

        findStokQuantityTitle() {
            if (this.form.type === 'group') {
                return 'Kontenjan';
            }

            if (this.form.type === 'private') {
                return 'Adet';
            }

            return 'Adet';
        },

        handleFindDiscountPercent(discount) {
            this.discountPercentLoading = true;

            let form = discount

            form.process = 'discount'
            form.content_id = this.id
            form.product_id = this.form.id
            form.main_price = this.form.price

            this.$http
                .put(this.createShowPageLink(), form)
                .then(response => {
                    this.discountPercentLoading = false;

                    discount.percent = response.body.data.percent

                    return this.responseSuccess(response);
                })
                .catch(response => {
                    this.discountPercentLoading = false;
                    return this.responseError(response);
                })
        }
    }
}
</script>
