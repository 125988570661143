<template>
	<div v-loading="pageLoading">
		<el-card class="custom-padding">
			<template slot="header">
				<CrudHeader ref="CrudHeader" :key="$route.name" :form="form" />
			</template>
			<el-form :label-position="'top'" ref="form" :model="form">
				<div class="card-sub-title">Genel</div>
				<div class="card-custom-padding-box-items">
					<el-form-item label="Bagli Oldugu Kategori">
						<ti-select :options="categories" :container="form" model="parent_id" title="title_ui" placeholder="Bagli Oldugu Kategori" />
					</el-form-item>

					<el-form-item label="Iliskili Oldugu Kategoriler" v-if="$route.meta.alias !== 'forums'">
						<el-select v-model="form.suggestion_requests" placeholder="Iliskili Oldugu Kategoriler" clearable filterable multiple>
							<el-option-group v-for="category in $store.state.ui.categories" :key="category.value" :label="category.label">
								<el-option v-for="item in category.options" :key="item.value" :label="item.label" :value="item.value" />
							</el-option-group>
						</el-select>
					</el-form-item>

					<el-form-item label="Baslik">
						<el-input v-model="form.title" placeholder="Baslik" />
					</el-form-item>

                    <el-form-item label="Kisa Aciklama" class="is-editor">
                        <editor :container="form" model="short_description" :small="true" />
                    </el-form-item>

					<el-form-item label="Aciklama" class="is-editor" v-if="$route.meta.alias !== 'exam_promos' && $route.meta.alias !== 'city_promos'">
						<editor :container="form" model="description" />
					</el-form-item>

					<el-form-item label="Kisa Aciklama" class="is-editor" v-if="$route.meta.alias === 'exam_promos' || $route.meta.alias === 'city_promos'">
						<editor :container="form" model="description" :small="true" />
					</el-form-item>

					<el-form-item label="Aciklama" class="is-editor" v-if="$route.meta.alias === 'exam_promos' || $route.meta.alias === 'city_promos'">
						<editor :container="form.meta" model="promo_description" :small="true" />
					</el-form-item>
				</div>

                <template v-if="$route.meta.alias !== 'forums'">
                    <div class="card-sub-title">Resim</div>
                    <div class="card-custom-padding-box-items">
                        <el-row :gutter="24">
                            <el-col :span="4">
                                <el-form-item label="Liste Resmi">
                                    <fs v-model="form.file.path"/>
                                </el-form-item>
                            </el-col>

                            <el-col :span="4">
                                <el-form-item label="Kapak Resmi">
                                    <fs v-model="form.meta.cover_img"/>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>

                    <div class="card-sub-title d-flex justify-content-between align-items-center">
                        <div>
                            Seo
                        </div>
                        <div v-if="form.seo.slug">
                            <el-tooltip content="Duzenle" placement="left">
                                <el-button class="flat dark green" icon="el-icon-edit" @click="openBlankWindow('/#/tools/seo/links/' + form.seo.id)" />
                            </el-tooltip>
                            <el-tooltip content="Web Sitesinde Gor" placement="right">
                                <el-button class="flat dark yellow" icon="el-icon-s-promotion" @click="openBlankWindow('https://' + $store.state.ui.tenant.domain + '/' + form.seo.slug)"/>
                            </el-tooltip>
                        </div>
                    </div>
                    <div class="card-custom-padding-box-items">
                        <seo :container="form.seo" />
                    </div>

                    <div class="card-sub-title">Ozellikler</div>
                    <div class="card-custom-padding-box-items">
                        <el-form-item>
                            <el-switch v-model="form.meta.is_show_filter" active-text="Filtreleri Göster" />
                        </el-form-item>

                        <el-form-item>
                            <el-switch v-model="form.is_active" active-text="Aktif" />
                        </el-form-item>

                        <el-form-item label="Hedef Linki">
                            <el-input v-model="form.meta.target_link" placeholder="Hedef Linki" />
                        </el-form-item>
                        <el-form-item label="Siralama">
                            <el-input v-model="form.sort" placeholder="Siralama" type="number" prefix-icon="mdi mdi-counter" />
                        </el-form-item>
                    </div>

                    <div class="card-sub-title">Reklam</div>
                    <div class="card-custom-padding-box-items">
                        <div class="ui message mini yellow">
                            Siralama bilgilerini girerken sayfa basina gosterilecek icerik sayisina dikkat ederek giris yapilmasi gerekiyor. Ornegin icerik sayisi sayfa basina 15 ise;
                            2,8,10,16 seklinde giris yapamazsiniz sayfa sayisi 15 oldugu icin 16. reklam gosterilmeyecektir. Siralama bilginilerini girerken lutfen bosluk birakmadan sayilari virgul ile ayirarak giris islemini yapiniz.
                        </div>
                        <el-form-item label="Reklam Gosterim Sirasi">
                            <el-input v-model="form.meta.banners" placeholder="Reklam Gosterim Sirasi" />
                        </el-form-item>
                    </div>
                </template>

                <div class="card-sub-title">Tasarim</div>
                <div class="card-custom-padding-box-items">
                    <el-form-item label="Icerik Siralama Turu">
                        <lv-select :options="sorts" :container="form" model="sort_type" />
                    </el-form-item>
                    <el-form-item label="Icerik Sayisi">
                        <el-input v-model="form.per_page_limit" placeholder="Icerik Sayisi" type="number" />
                    </el-form-item>
                    <el-form-item label="Sablon">
                        <lv-select :options="layouts" :container="form" model="view_path" />
                    </el-form-item>
                    <el-form-item label="Renk">
                        <lv-select :options="colors" :container="form.meta" model="color" />
                    </el-form-item>
                    <el-form-item label="Ikon">
                        <el-input v-model="form.meta.icon" placeholder="Ikon" />
                    </el-form-item>
                    <el-form-item label="Html Class Etiketi">
                        <el-input v-model="form.meta.html_class_tag" placeholder="Html Class Etiketi" />
                    </el-form-item>
                </div>
			</el-form>
		</el-card>
	</div>
</template>

<script>
    import CrudHeader from "../../Components/CrudHeader";

    export default {
        name: 'CrudItem',
        components: {
            CrudHeader
        },
        data() {
            return {
                pageLoading: false,
                form: {
                    meta: {
                        banners : null,
                        is_show_filter : false
					},
                    file: {},
                    seo: {
                        slug : null,
                    },
                },

                categories: [],
                sorts: [
                    {
                        value: 'created_at_desc',
                        label: 'Olusturulma Tarihine Gore: Azalan',
                    },
                    {
                        value: 'created_at_asc',
                        label: 'Olusturulma Tarihine Gore: Artan',
                    },
                    {
                        value: 'updated_at_desc',
                        label: 'Guncellenme Tarihine Gore: Azalan',
                    },
                    {
                        value: 'updated_at_asc',
                        label: 'Guncellenme Tarihine Gore: Artan',
                    },
                    {
                        value: 'sort_desc',
                        label: 'Siralama Numarasina Gore: Azalan',
                    },
                    {
                        value: 'sort_asc',
                        label: 'Siralama Numarasina Gore: Artan',
                    },
                    {
                        value: 'publish_at_desc',
                        label: 'Yayinlanma Tarihine Gore: Azalan',
                    },
                    {
                        value: 'publish_at_asc',
                        label: 'Yayinlanma Tarihine Gore: Artan',
                    }
                ],
                colors: [
                    {
                        value: 'blue',
                        label: 'Mavi',
                    },
                    {
                        value: 'yellow',
                        label: 'Sarı',
                    },
                    {
                        value: 'orange',
                        label: 'Turuncu',
                    },
                    {
                        value: 'red',
                        label: 'Kirmizi',
                    },
                    {
                        value: 'purple',
                        label: 'Mor',
                    },
                    {
                        value: 'green',
                        label: 'Yeşil',
                    },
                    {
                        value: 'english',
                        label: 'English',
                    },
                    {
                        value: 'german',
                        label: 'German',
                    },
                    {
                        value: 'french',
                        label: 'French',
                    },
                    {
                        value: 'arabic',
                        label: 'Arabic',
                    },
                    {
                        value: 'russian',
                        label: 'Russian',
                    },
                    {
                        value: 'spanish',
                        label: 'Spanish',
                    },
                    {
                        value: 'dutch',
                        label: 'Dutch',
                    },
                    {
                        value: 'italian',
                        label: 'Italian',
                    }
                ],
                layouts: [
                    {
                        value: 'events',
                        label: 'Etkinlik',
                    },
                    {
                        value: 'apps',
                        label: 'Uygulamalar',
                    },
                    {
                        value: 'programs',
                        label: 'Egitim Programlari',
                    },
                    {
                        value: 'shop',
                        label: 'Magaza',
                    }
				],
            }
        },
        mounted() {
            this.getCategories();

            if (this.$route.params.id) {
                this.show();
            }

            if (this.$route.meta.alias === 'forums') {
                this.form.seo = {};
            }
        },
        methods: {
            getCategories() {
                this.pageLoading = true;

                this.$http
					.get(this.generateApiPointWithAlias(this.$route.meta.apiPoint), {
						params: {
							perPage: 1000,
							filters: [
								{
									relation: null,
									inputs: [
										{
											name: 'id',
											field: 'id',
											type: 'notIn',
											value: [this.$route.params.id]
										}
									]
								},
							],
						}
					})
                    .then(response => {
                        this.categories = response.body.rows;
                        this.pageLoading = false;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            show() {
                this.pageLoading = true;

                this.$http
                    .get(this.createShowPageLink())
                    .then(response => {
                        this.form = response.body.data;

                        if (!this.lodash.size(this.form.meta)) {
                            this.form.meta = {
                                //
							};
                        }

                        if (!this.form.file) {
                            this.form.file = {};
                        }

                        if (!this.form.seo) {
                            this.form.seo = {};
                        }

                        this.pageLoading = false;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },
        }
    }
</script>
