
<template>
    <div v-loading="pageLoading" class="question-page">
        <el-card class="custom-padding">
            <template slot="header">
                <div class="crud-header">
                    <div class="title">{{ form.branch }}</div>
                    <div class="actions">
                        <el-tooltip content="Geri" placement="bottom">
                            <el-button class="flat dark grey" icon="el-icon-back" @click="goIndexPage"/>
                        </el-tooltip>
                        <el-dropdown trigger="click" @command="handleSocialCommand">
                          <span class="el-dropdown-link margin-right-10 margin-left-10">
                              <el-tooltip content="Sosyal Medya" placement="bottom">
                                  <el-button class="flat dark purple " icon="el-icon-link"/>
                              </el-tooltip>
                          </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item command="osym">Osym Sever</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                        <el-tooltip content="On Izle" placement="bottom">
                            <el-button class="flat dark blue" icon="el-icon-view" @click="previewDialogOpen"/>
                        </el-tooltip>

                        <el-tooltip content="Sil" placement="bottom">
                            <el-button class="flat dark red" icon="el-icon-delete" @click="destroy" :loading="loading" :disabled="loading"/>
                        </el-tooltip>

                        <el-tooltip content="Kaydet" placement="bottom">
                            <el-button class="flat dark green" icon="el-icon-check" @click="update" :loading="loading" :disabled="loading">
                                Kaydet
                            </el-button>
                        </el-tooltip>
                    </div>
                </div>
            </template>
        </el-card>

        <el-form label-position="top" ref="form" :model="form">

            <el-row :gutter="24">
                <el-col :span="20">
                    <el-card>
                        <template slot="header">
                            <div class="crud-header">
                                <div class="title">Soru Bilgileri</div>
                            </div>
                        </template>
                        <el-row :gutter="24">
                            <el-col :span="6">
                                <el-form-item label="Soru Türü">
                                    <lv-select :options="types" :container="form" model="question_type" placeholder="Soru Türü"/>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="Soru Şekli">
                                    <lv-select :options="views" :container="form" model="view_type" placeholder="Soru Şekli"/>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="Kaynak Türü"  class="margin-bottom-0">
                                    <lv-select :options="owner_types" :container="form" model="owner_type" placeholder="Kaynak Türü"/>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="Sınavlar" v-if="form.owner_type === 'examination'"  class="margin-bottom-0">
                                    <ti-select :options="examinations" :container="form" model="archive_examination_id" title="title_ui" placeholder="Sınavlar"/>
                                </el-form-item>

                                <el-form-item label="Yazar" v-if="form.owner_type === 'owner'"  class="margin-bottom-0">
                                    <user-with-role role="teacher" :container="form" model="owner_id" placeholder="Yazar"/>
                                </el-form-item>
                            </el-col>
                            <el-col :span="24">
                                <el-form-item label="Konu Etiketleri" :key="form.archive_branch_id">
                                    <el-cascader v-model="form.topic_cascader_requests" :options="topics" :props="{multiple: true}" clearable filterable/>
                                </el-form-item>
                            </el-col>
                            <el-col :span="24">
                                <el-form-item label="Etiketler" class="margin-bottom-0">
                                    <definition-box alias="questions" :container="form" model="tag_requests" :multiple="true" placeholder="Etiketler"/>
                                </el-form-item>
                            </el-col>
                            <el-col :span="24">
                                <el-form-item label="Bagli Soru Kok" class="margin-bottom-0 margin-top-20" v-if="form.question_type === 'connected'">
                                    <div class="content-editor" placeholder="Aciklama">
                                        <content-editor ref="ContentEditor" :container="form" model="description"/>
                                    </div>
                                    <template v-if="form.archive_branch_id === ui.settings.english_archive_branch_id">
                                        <div class="content-editor-divider"></div>
                                        <div class="content-editor" placeholder="Ceviri">
                                            <content-editor ref="ContentEditor" :container="form" model="translation"/>
                                        </div>
                                    </template>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-card>
                    <div class="question-content-section">
                        <div class="question-tabs margin-bottom-20" v-if="form.question_type === 'connected'">
                            <draggable tag="div" v-model="form.questions" :group="{ name: 'questios' }" @end="setQuestionSort" class="question-tabs">
                                <template v-for="(question, index) in form.questions">
                                    <div class="question-tab" :class="{active : selectedQuestionIndex === index}" :key="question.id" @click="selectedQuestionIndex = index">
                                        Soru {{ index + 1 }}
                                    </div>
                                </template>
                            </draggable>
                            <div class="question-tab action-btn" @click="createQuestion">
                                <icon icon="plus"/>
                            </div>
                        </div>

                        <template v-for="(question, index) in form.questions">
                            <el-card v-show="index === selectedQuestionIndex" class="question-card custom-padding" :key="index">
                                <template slot="header">
                                    <div class="crud-header">
                                        <div class="title">
                                            Soru {{ index + 1 }}
                                        </div>
                                        <div class="actions" v-if="form.question_type === 'connected'">
                                            <el-tooltip content="Sil" placement="bottom">
                                                <el-button class="flat dark red" icon="el-icon-delete" @click="destroyQuestion(question.id, index)"/>
                                            </el-tooltip>
                                        </div>
                                    </div>
                                </template>

                                <div class="padding-20">
                                    <el-form-item label="Soru Kökü">
                                        <div class="content-editor" placeholder="Soru Koku">
                                            <content-editor :container="question" model="description"/>
                                        </div>
                                    </el-form-item>

                                    <template
                                        v-if="form.archive_branch_id === ui.settings.english_archive_branch_id">
                                        <div class="content-editor-divider"></div>
                                        <div class="content-editor" placeholder="Ceviri">
                                            <content-editor :container="question" model="translation"/>
                                        </div>
                                    </template>

                                    <el-form-item label="Dinleme Dosyası" class="margin-top-20 mb-0" v-if="form.view_type === 'listening_multiple_choice'">
                                        <fs v-model="question.meta.file_src" :isFile="true"/>
                                    </el-form-item>
                                </div>

                                <template v-if="form.view_type !== 'description'">
                                    <div class="card-sub-title">
                                        <div class="title">Options</div>
                                        <div class="actions">
                                            <el-tooltip content="Secenek Ekle" placement="bottom">
                                                <el-button class="flat dark blue" icon="el-icon-plus" @click="createQuestionOption(question)"/>
                                            </el-tooltip>
                                        </div>
                                    </div>
                                    <draggable class="options" tag="div" v-model="question.options" :group="{ name: 'q-options-' + question.id }" @end="setQuestionOptionSort(question)">
                                        <div class="option" v-for="(option, optionIndex) in question.options" :key="optionIndex">
                                            <div class="actions">
                                                <el-tooltip content="Dogru Cevap" placement="top">
                                                    <div class="prefix action" :class="{active : option.is_correct}" @click="handleUpdateQuestionOptionCorrect(question, option.id)">
                                                        <icon :icon="'letter-' + question.option_view_type + '-' + (optionIndex + 1)"/>
                                                    </div>
                                                </el-tooltip>
                                                <el-tooltip content="Sil" placement="top">
                                                    <div class="delete action" @click="destroyQuestionOption(question, option.id, optionIndex)">
                                                        <icon icon="trash-can-outline"/>
                                                    </div>
                                                </el-tooltip>
                                                <el-tooltip content="Sirasini Degistir" placement="top">
                                                    <div class="move action">
                                                        <icon icon="cursor-move"/>
                                                    </div>
                                                </el-tooltip>
                                            </div>
                                            <div class="content">
                                                <div class="content-editor">
                                                    <content-editor :container="option" model="description" :small="true"/>
                                                </div>
                                            </div>
                                        </div>
                                    </draggable>
                                </template>
                            </el-card>
                        </template>
                    </div>
                </el-col>
                <el-col :span="4">
                    <el-card class="card-flat-list overflow-400">
                        <template slot="header">
                            <div class="crud-header">
                                <div class="title">Kullanildigi Yerler</div>
                            </div>
                        </template>

                        <div class="card-flat-list-items sm" v-scroll:throttle="{ throttle: 500 }">
                            <div class="item" v-for="item in form.tests" :key="item.id">
                                <div class="text display-flex align-items-center">
                                    <div class="title fluid cursor-pointer" @click="openBlankWindow('/#/educations/tests/' + item.id)">
                                        {{ item.title }}
                                    </div>
                                    <div>{{ item.sort }}</div>
                                </div>
                            </div>
                        </div>
                    </el-card>

                    <Note :path="$route.fullPath"/>
                </el-col>
            </el-row>
        </el-form>

        <el-dialog title="On Izleme" :visible.sync="previewDialogVisible" class="dialog-with-page body-overflow" append-to-body>
            <PreviewQuestion :questionContent="form" :key="form.id" v-if="form.id"/>
        </el-dialog>
    </div>
</template>

<script>
import {mapState} from "vuex";
import draggable from "vuedraggable";
import Note from "@/app/Components/Note";
import PreviewQuestion from "@/app/Pages/Archives/QuestionContent/Preview";

export default {
    name: 'CrudItem',
    components: {
        PreviewQuestion,
        Note,
        draggable
    },
    computed: mapState(['account', 'ui']),
    data() {
        return {
            pageLoading: false,
            loading: false,
            form: {
                topic_cascader_requests: [],
                audience_cascader_requests: [],
            },

            types: [
                {
                    value: 'singular',
                    label: 'Tekil',
                },
                {
                    value: 'connected',
                    label: 'Bağlı',
                }
            ],
            views: [
                {
                    value: 'multiple_choice',
                    label: 'Çoktan Seçmeli',
                },
                {
                    value: 'description',
                    label: 'Seceneksiz Soru',
                },
                {
                    value: 'fill_in_the_blank',
                    label: 'Bosluk Doldurma',
                },
                {
                    value: 'listening_multiple_choice',
                    label: 'Dinlemeli Çoktan Seçmeli',
                },
            ],
            owner_types: [
                {
                    value: 'examination',
                    label: 'Gerçek Sınav',
                },
                {
                    value: 'owner',
                    label: 'Orjinal',
                }
            ],
            topics: [],
            audiences: [],
            examinations: [],
            knowledges: [],
            levels: [],

            // Questions
            selectedQuestionIndex: 0,

            previewDialogVisible: false,
        }
    },
    mounted() {
        this.show();

        window.onbeforeunload = () => {
            return 'Sekme kapaniyor, yaptiginiz islemleri kaydettiginizden emin misiniz?';
        }
    },
    methods: {
        show() {
            this.pageLoading = true;

            this.$http
                .get(this.createShowPageLink())
                .then(response => {
                    this.form = response.body.data;

                    if (!this.lodash.size(this.form.meta)) {
                        this.form.meta = {
                            //
                        };
                    }

                    // Relation
                    this.getTopics();
                    this.getAudiences();
                    this.getExaminations();
                    this.getLevels();
                    this.getKnowledges();
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        update() {

            if (this.loading) {
                return;
            }

            this.loading = true;

            this.$http
                .put(this.createShowPageLink(), this.form)
                .then(response => {
                    this.loading = false;
                    this.dialogVisible = false;

                    return this.responseSuccess(response);
                })
                .catch(response => {
                    this.loading = false;
                    return this.responseError(response);
                })
        },

        destroy() {
            this
                .$confirm('Verileriniz kalici olarak siliniyor. Onayliyor musunuz?', 'Uyari', {
                    confirmButtonText: 'Onayla',
                    cancelButtonText: 'Iptal',
                    type: 'warning'
                })
                .then(() => {
                    this.$http
                        .delete(this.$route.meta.apiPoint + '/' + this.form.id)
                        .then(() => {
                            return this.goRoute('/archives/question-contents/')
                        })
                        .catch(response => {
                            this.responseError(response);
                        });

                    return true;
                })
                .catch(() => {
                    this.$notify({
                        type: 'success',
                        message: 'Isleminiz basarili bir sekilde iptal edildi!'
                    });

                    return false;
                });
        },

        getTopics() {
            this.pageLoading = true;

            this.$http
                .get('archives/topics', {
                    params: {
                        perPage: 10000,
                        view: 'cascader',
                        filters: [
                            {
                                relation: null,
                                inputs: [
                                    {
                                        name: 'archive_branch_id',
                                        field: 'archive_branch_id',
                                        type: 'singleSelect',
                                        value: this.form.archive_branch_id
                                    },
                                ]
                            },
                        ],
                    }
                })
                .then(response => {
                    this.pageLoading = false;
                    this.topics = response.body.data;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        getAudiences() {
            this.pageLoading = true;

            this.$http
                .get('archives/audiences', {params: {perPage: 10000, view: 'cascader'}})
                .then(response => {
                    this.pageLoading = false;
                    this.audiences = response.body.data;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        getExaminations() {
            this.pageLoading = true;

            this.$http
                .get('archives/examinations', {params: {perPage: 10000}})
                .then(response => {
                    this.pageLoading = false;
                    this.examinations = response.body.rows;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        getKnowledges() {
            this.pageLoading = true;

            this.$http
                .get('archives/knowledges', {params: {perPage: 10000, view: 'cascader'}})
                .then(response => {
                    this.pageLoading = false;
                    this.knowledges = response.body.data;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        getLevels() {
            this.pageLoading = true;

            this.$http
                .get('archives/levels', {params: {perPage: 10000, view: 'cascader'}})
                .then(response => {
                    this.pageLoading = false;
                    this.levels = response.body.data;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        /**
         * Question sort
         */
        setQuestionSort() {

            let sorts = [];

            this.lodash.forEach(this.form.questions, (item, index) => {
                sorts.push({
                    id: item.id,
                    sort: index
                });

                this.form.questions[index].sort = index;
            });

            this.$http
                .post(this.$route.meta.apiPoint + '/' + this.form.id + '/questions', {
                    process: 'sortable',
                    items: sorts
                })
                .then(response => {
                    return this.responseSuccess(response);
                })
                .catch(response => {
                    return this.responseError(response);
                })
        },

        /**
         * Create question item
         */
        createQuestion() {
            this.pageLoading = true;

            this.$http
                .post(this.$route.meta.apiPoint + '/' + this.form.id + '/questions', {})
                .then(response => {
                    this.pageLoading = false;
                    this.dialogVisible = false;

                    this.form.questions.push(response.body.data);
                    this.selectedQuestionIndex = 0;

                    return this.responseSuccess(response);
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        /**
         * Destroy question
         */
        destroyQuestion(id, index) {
            this
                .$confirm('Verileriniz kalici olarak siliniyor. Onayliyor musunuz?', 'Uyari', {
                    confirmButtonText: 'Onayla',
                    cancelButtonText: 'Iptal',
                    type: 'warning'
                })
                .then(() => {

                    this.$http
                        .delete(this.$route.meta.apiPoint + '/' + this.form.id + '/questions/' + id)
                        .then(response => {
                            this.responseSuccess(response);
                            this.form.questions.splice(index, 1);
                            this.selectedQuestionIndex = 0;
                        })
                        .catch(response => {
                            this.responseError(response);
                        });


                    return true;

                })
                .catch(() => {
                    this.$notify({
                        type: 'success',
                        message: 'Isleminiz basarili bir sekilde iptal edildi!'
                    });

                    return false;
                });
        },

        /**
         * Create question option
         */
        createQuestionOption(question) {
            this.pageLoading = true;

            this.$http
                .post(this.$route.meta.apiPoint + '/' + this.form.id + '/questions/' + question.id + '/options', {archive_question_content_question_id: question.id})
                .then(response => {
                    this.pageLoading = false;
                    this.dialogVisible = false;

                    question.options.push(response.body.data)

                    return this.responseSuccess(response);
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        handleUpdateQuestionOptionCorrect(question, id) {
            this.lodash.forEach(question.options, (item) => {
                item.is_correct = null;

                if (item.id === id) {
                    item.is_correct = true;
                }
            });
        },

        /**
         * Destroy question option
         */
        destroyQuestionOption(question, id, index) {

            this
                .$confirm('Verileriniz kalici olarak siliniyor. Onayliyor musunuz?', 'Uyari', {
                    confirmButtonText: 'Onayla',
                    cancelButtonText: 'Iptal',
                    type: 'warning'
                })
                .then(() => {

                    this.$http
                        .delete(this.$route.meta.apiPoint + '/' + this.form.id + '/questions/' + question.id + '/options/' + id)
                        .then(response => {

                            question.options.splice(index, 1);

                            this.responseSuccess(response);

                        })
                        .catch(response => {
                            this.responseError(response);
                        });


                    return true;

                })
                .catch(() => {
                    this.$notify({
                        type: 'success',
                        message: 'Isleminiz basarili bir sekilde iptal edildi!'
                    });

                    return false;
                });
        },

        /**
         * Question option sort
         */
        setQuestionOptionSort(question) {
            this.lodash.forEach(question.options, (option, index) => {
                option.sort = index + 1;
            });
        },

        previewDialogOpen() {
            this.previewDialogVisible = true;

            setTimeout(() => {
                this.initMathJax(document.getElementById('QuestionPreview').getElementsByTagName('math'));
            }, 500)
        },

        handleSocialCommand(event) {
            if (event === 'osym') {
                return window.open('https://' + this.$store.state.ui.tenant.domain + '/social/share/questions/' + this.form.id, '_blank');
            }
        }
    }
}
</script>
